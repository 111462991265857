import "./style.css";

import React, {useContext, useEffect} from "react";
import {Layout, theme} from "antd";
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import Categories, {allCats} from "./Categories";
import Templates from "./Templates";

import {GlobalContext} from "../GlobalContext";


export default function Welcome() {

    const {
        // config,
        // curUserInfo,
        path, setPath,
        app, setApp,
    } = useContext(GlobalContext)

    useEffect(() => { // «ИНИЦИАЛИЗАЦИЯ»
        if( path.length > 1 && !(app.storage) ){ // задан какой-то УРЛ, но хранилище не определено
            const parsePaths = path.split("/")
            const newApp = {}
            if(parsePaths[2]) newApp.storage  = {code: parsePaths[2], }
            if(parsePaths[4]) newApp.category = {key:  parsePaths[4],                          src: parsePaths[2], }
            if(parsePaths[6]) newApp.template = {code: parsePaths[6], category: parsePaths[4], src: parsePaths[2], }

            if(newApp.template && !(newApp.category)) newApp.category = allCats

            setApp(newApp)
        }
    }, [path])

    useEffect(() => {
        console.log(app)
        let newPath = ""
        if(app.storage){
            newPath += "/src/"+(app.storage.code)
            if(app.category){
                newPath += "/cat/"+(app.category.key || app.category.name)
                if(app.template) newPath += "/tmpl/"+(app.template.code)
            }
        }
        // console.log("newPath", newPath)
        if(path !== newPath) setPath(newPath)
    }, [app])

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
            <Layout style={{ padding: "24px 0", background: colorBgContainer, borderRadius: borderRadiusLG }}>

                {!(app && app.storage && (app.storage.permissions || []).indexOf("catTree") >= 0) ? <></>
                 : <Sider id={"categories"} theme={"light"} width={""}>
                    <Categories />
                 </Sider>
                }

                <Content style={{ padding: "0 24px", minHeight: 280 }}>
                    <Templates />
                </Content>
            </Layout>
    )
}
