// import {useRef} from "react";
import {Button, Input, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import genUUID from "./uuid";

export const getColumnSearchProps = (dataIndex, dataCaption, setSearchText, setSearchedColumn) => {

    // const searchInput = useRef(null);
    const searchInputId = genUUID();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText && setSearchText(selectedKeys[0]);
        setSearchedColumn && setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText && setSearchText("");
    };

    return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
                padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
                // ref={searchInput}
                id={searchInputId}
                placeholder={`Фильтр по полю «${dataCaption || dataIndex}»`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{
                    marginBottom: 8,
                    display: "block",
                }}
            />
            <Space>
                <Button type="primary" size="small"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                >
                    Найти
                </Button>
                <Button size="small"
                        onClick={() => clearFilters && handleReset(clearFilters)}
                >
                    Сбросить
                </Button>
                <Button type="link" size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText && setSearchText(selectedKeys[0]);
                            setSearchedColumn && setSearchedColumn(dataIndex);
                        }}
                >
                    Применить
                </Button>
                <Button  type="link"  size="small"
                         onClick={() => { close() }}
                >
                    Закрыть
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined
            style={{
                color: filtered ? '#1677ff' : undefined,
            }}
        />
    ),
    onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdownProps: {
        onOpenChange(open) {
            if (open) {
                setTimeout(() => ( document.getElementById(searchInputId) /*searchInput.current*/)?.select(), 100);
            }
        },
    },
    /*
    render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{
                    backgroundColor: '#ffc069',
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    */
    }
}
