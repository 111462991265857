import "./style.css"

import React, {useEffect, useState} from "react";
import {gFetch} from "../../core/token";

import { Layout } from 'antd';

export default function Footer() {
    const [versionInfo, setVersionInfo] = useState("")

    useEffect(()=>{
        gFetch("/version.info")
            .then(it => ( ( it && it.text) ? it.text() : "" ))
            .then(it => it.split(/\n/g))
            .then(it => {
                const vi = {}
                it.map( l => l.split(":"))
                    .forEach(l => {
                        vi[l[0]] = l.slice(1).join(":")
                    })
                return vi
            })
            .then(it => setVersionInfo(it))
    }, [])

    return (<Layout.Footer
        id={"footer"}
    >
        { Object.keys(versionInfo).map(k => (
        <p className={k}><i>{k}</i><b>{versionInfo[k]}</b></p>
    )) }</Layout.Footer>);
}
