import {toast} from "./toast";
import {linkTo} from "./oth";

const apusCode   = "apus";

const jwtAccTokenUrlItem = "CONFIG_JWT_ACCTOKEN_URL";

const getJwtAccTokenUrl = () => (localStorage.getItem(jwtAccTokenUrlItem))
export const setJwtAccTokenUrl = (url) => (localStorage.setItem(jwtAccTokenUrlItem, url))

export const gFetch = async (str, params) => {
    if( refreshTokensInProcess ) refreshTokensInProcess.then( _ => ( gFetch(str, params) ) )

    if (str !== "/config") {// для работы gFetch требуется нормально получение этого конфига!!!
        const accToken = accessToken()
        if (accToken && accToken !== "null") {
            params = params || {}
            params.headers = params.headers || {}
            params.headers["Authorization"] = "Bearer " + (accToken)
        }
    }
    return await window.fetch(linkTo(str), params)
        .then(async it => {
            if (it.status === 524) {
                return doRefreshTokens().then(async () => (gFetch(str, params)))
            } else {
                if(it.status >= 400){
                    const content = await it.text()
                    const obj = {
                        status: "error",
                        type: it.status,
                        content: content,
                    }
                    toast(obj)
                    return new Promise((resolve,reject)=>{resolve({json: ()=>(
                        Object.assign({permissions:[]}, obj)
                        )})});
                }else {
                    return it
                }
            }
        })
}

export const accessToken  = ()=>( JSON.parse( atob( sessionStorage.getItem(apusCode)|| "") || "{}" ).access_token  )
export const refreshToken = ()=>( JSON.parse( atob( sessionStorage.getItem(apusCode)|| "") || "{}" ).refresh_token )

export const saveToken = (token)=>{
    token = token || {};
    const newApus = Object.assign(JSON.parse( atob( sessionStorage.getItem( apusCode )  || "") || "{}" ), {access_token: token.access_token, refresh_token: token.refresh_token})
    sessionStorage.setItem(apusCode, btoa( JSON.stringify(newApus) ))
}

let refreshTokensInProcess = false
async function doRefreshTokens() {
    const jwtAccTokenUrl = getJwtAccTokenUrl() // useContext(GlobalContext).config["JWT_ACCTOKEN_URL"]
    if(refreshTokensInProcess || !jwtAccTokenUrl){
        return refreshTokensInProcess;
    }

    refreshTokensInProcess = fetch(jwtAccTokenUrl, {
        method: "POST",
        headers: {"Content-Type": "text/plain"},
        body: JSON.stringify({
            refresh_token: refreshToken(),
            grant_type: "refresh_token",
        }),
    })
        .then((it) => {
            return it.json();
        })
        .then((it) => {
            // if(it.errors) appendErrors(it.errors);
            saveToken(it);
            refreshTokensInProcess.then()
            refreshTokensInProcess = false;
            return this;
        });
    return await refreshTokensInProcess
}


export const fetchSessData = function ( setter ) {
    gFetch("/sess")
        .then(it => {
            return it && it.json()
        })
        .then(usr => {
            if(usr) {
                usr.state = true
            }else{
                usr = {state: false}
            }
            const lsKey =  "user-"+usr.sub
            if(usr.acc) window["acc"] = usr.acc;

            usr.displayName = [usr.family_name, usr.given_name, usr.middle_name].join(" ").trim();
            if(usr.displayName.length === 0) usr.displayName = usr.nickname;
            if( usr.picture && usr.picture.slice(0,4).toLowerCase() === "http" ){ //если url, то получаем по нему картинку
                const pic = usr.picture;
                const kache = sessionStorage.getItem(pic);
                if (kache) {
                    usr.picture = kache;
                    setter(usr);
                }else {
                    gFetch(pic, {method: "GET", headers: {"Authorization": "Bearer " + usr.acc}})
                        .then(it => {
                            return it.blob()
                        })
                        .then((it) => {
                            const fr = new FileReader();
                            fr.readAsDataURL(it);
                            fr.onloadend = () => {
                                usr.picture = "";
                                if(fr.result.indexOf("data:application/json") < 0) { //если не ошибка
                                    sessionStorage.setItem(pic, fr.result); //кешируем
                                    usr.picture = fr.result; //сохраняем у пользователя вместо url саму картинку
                                    sessionStorage.setItem(lsKey, JSON.stringify( usr )) // записать в кэш
                                    setter(usr); // перерисовываем инфо о пользоваетеле
                                }else{
                                    setter(usr); // если ошибка, то не кешируем
                                }
                            }
                        })
                        .catch(it => { // ну, не вышло, и не вышло (
                            console.log(it)
                            setter(usr);
                        })
                }
            }else {
                sessionStorage.setItem(lsKey, JSON.stringify( usr )) // записать в кэш
                setter(usr)
            }
        })
}