import ReactDOM from "react-dom/client";

import {gFetch} from "../../../core/token";
import {getOrCreate} from "../../../core/oth";
import TemplateEditor from "./TemplateEditor";
import {asFormData, entries} from "../../../core/form";
import TemplateAdder from "./TemplateAdder";
import Builder from "./Builder";

export const fetchTemplates = (src, setter) => {
    gFetch("/api/templates?src="+(src==="/" ? "" : src ), {method: "GET"})
        .then( it => { return it.json() })
        .then( it => {
            if(it) {
                if (it.type === "err") {
                    alert(it.id + ": " + it.title + "\r\n\r\n" + it.category)
                } else {
                    setter( it )
                }
            }
        })
}

export const saveTemplate = function (o, afterSave) {
    gFetch("/api/templates", {method: "POST", body: asFormData(o)})
        .then( it => { return it.json() })
        .then( it => {
            if( it.type === "err" ){
                alert( it.id +": "+ it.title + "\r\n\r\n" + it.category)
            }else {
                afterSave(it)
            }
        });
}

export const showFormForAddTemplate = (event, tmpl, setter)=>{
    event.preventDefault()
    event.stopPropagation()
    ReactDOM.createRoot(getOrCreate()).render(
        <TemplateAdder
            template={tmpl}
            onSave={(fd)=>{
                saveTemplate(fd, setter)
            }}
        />
    )
}
export const showFormForUpdTemplate = (event, tmpl, setter)=>{
    event.preventDefault()
    event.stopPropagation()
    ReactDOM.createRoot(getOrCreate()).render(
        <TemplateEditor
            template={tmpl}
            onSave={(f)=>{
                const c = entries(f)
                c.src = tmpl.src
                c.requestType = "templateUpdate"
                saveTemplate(c, setter)
            }}
        />
    )
}
export const showFormForDelTemplate = (event, tmpl, setter)=>{
    event.preventDefault()
    event.stopPropagation()
    // if(window.confirm("Точно удалить?")){
        saveTemplate({src: tmpl.src, code: tmpl.code, requestType: "templateDelete"}, setter)
    // }
}

export const showBuilder = (tmpl, closer)=>{
    // console.log("showBuilder", tmpl)
    ReactDOM.createRoot(getOrCreate()).render(
        <Builder
            template={tmpl}
            onClose={(e)=>{
                closer && closer(e)
            }}
        />
    )
}
