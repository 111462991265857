import React, {useRef} from "react";

import Dialog from "../../../../core/Dialog";
import {Form, Input, Space} from "antd";

export default function CategoryEditor({
                    cat = {}, // as Category
                    onSave = (cat)=>{},
                  }){

    const fref = useRef()

    return (<Dialog
        title={ cat.name ? "Категория «"+cat.name+"»" : "Новая категория"}
        buttonCaption="Сохранить"
        buttonAction={(e, cb) => {
            onSave( fref.current.nativeElement || fref.current )
            cb && cb(true);
        }}
    >
        <Space direction={"vertical"}>
            <Form
                ref={fref}
                onSubmit={e=>e.preventDefault()}
            >
                <Form.Item
                    name={"title"}
                    label={""}
                >
                    <Input type="text" name="title"
                           defaultValue={cat.name||""}
                           placeholder={"Наименование"}
                           required
                           maxLength={80} showCount
                    />
                </Form.Item>

                { cat.id ? <input type="hidden" name="id" value={cat.id} required readOnly /> : <></> }
                <input type="hidden" name="src" value={cat.src||""} required readOnly />
                <input type="hidden" name="category" value={cat.category||""} required readOnly />
                <input type="hidden" name="requestType" value={cat.id ? "categoryUpdate" : "categoryAdd"} required readOnly />
            </Form>
        </Space>
    </Dialog>)
}
