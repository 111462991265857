import './style.css';

import React, {useState} from "react";

// import {GlobalContext} from "../GlobalContext";

export default function Promo({
}) {

    const toc = [
        {href: "first.pdf",   content: "Что это вообще такое"},
        {href: "tune-it.pdf", content: "Развертывание Настройка Нюансы работы с БД"},
    ]

    // const {config, curUserInfo, path, setPath} = useContext(GlobalContext)

    const [content, setContent] = useState(toc[0])

    return (<div className={"promo"}>
        <div className={"toc"}>
            <ul>
                {toc.map( (it) => (
                    <li>
                        <a href={"/promo/"+it.href}
                           className={ it.href==content.href ? "active" : "" }
                           onClick={(e) => {
                               e.preventDefault();
                               setContent(it);
                           }}
                        >{it.content}</a>
                    </li>
                ))}
            </ul>
        </div>
        <div className={"content"}>
            <fieldset>
                <legend>{content.content}</legend>
                <embed src={content.href} type={"application/pdf"}/>
            </fieldset>
        </div>
    </div>);
}
