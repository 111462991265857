import "./style.css"

import React from "react";
import ReactDOM from "react-dom/client";
import {getOrCreate} from "../oth";

let tid = 0

export function toast({type = "", content = (<></>)}){
    const container = getOrCreate("toast-"+(++tid),"p", getOrCreate("toasts"))

    container.classList.add("toast", "toast-"+type, "hid")

    ReactDOM.createRoot( container ).render((
        <Toast>{content}</Toast>
    ))

    setTimeout(function(){
        container.classList.remove("hid");
        setTimeout(function(){
            container.classList.add("hid");
            setTimeout(function(){
                container.remove();
            }, 1000)
        }, 7000)
    }, 1)

}

export const Toast = ({
                          children = (<></>),
                      }) => {
    return (<>{children}</>)
}
