import "./style.css";

import React, {useContext} from "react";
import {GlobalContext} from "../GlobalContext";
import {Tabs} from "antd";
import {DesktopOutlined, HomeOutlined, InfoOutlined} from "@ant-design/icons";

const menu = [
    {
        key: "/",
        icon: <HomeOutlined/>,
        label: "Домой",
    },
    {
        key: "/mans/",
        icon: <InfoOutlined/>,
        label: "Документация",
    },
    {
        key: "/promo/",
        icon: <DesktopOutlined/>,
        label: "Презентации",
    },
];

export default function Menu() {
    const {
        // config,
        // curUserInfo,
        path,
        setPath,
    } = useContext(GlobalContext)

    return <Tabs
                activeKey={
                    path === "/" ? path
                        : (menu.slice(1).map(it=>(it.key)).filter( it => ( path.indexOf(it) === 0 ) )[0])
                }
                items={menu}
                onChange={setPath}
            />
}
