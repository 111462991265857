import "./style.css";

import React, {useEffect, useState} from "react";
import {Avatar} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {fetchUserInfo} from "./funcs";

export default function User({
                       className="",
                       sub = "",
                       children = "",
                   }) {
    const [state, setState] = useState({type: ""});
    const [userInfo, setUserInfo] = useState( {} );

    // const sub = path.replace(/^.*\/users\/([^\/]+)\/?.*$/, "$1");

    useEffect(() => {
        const userId = (sub||children)
        if(userId) {
            const lsKey = "user-" + userId
            const cache = sessionStorage.getItem(lsKey)
            let jUserInfo
            if (cache) {
                jUserInfo = JSON.parse(cache)
            }
            if (jUserInfo && (jUserInfo.sub == userId)) {
                setUserInfo(jUserInfo)
                setState({type: "ok"})
            } else {
                fetchUserInfo(userId
                    , (ui) => {
                        setUserInfo(ui)
                        if (ui.sub == userId) {
                            sessionStorage.setItem(lsKey, JSON.stringify(ui))
                        }
                    }, setState);
            }
        }
    }, [sub, children]);

    return (<>
              {(state.type !== "ok"
              ? <></>
              : <span className={"user "+className} data-sub={userInfo.sub}>

                  {/*
                  <span className="user-nickname">{userInfo.nickname || userInfo.login || userInfo.username}</span>
                  */}

                  { (userInfo.avatar || userInfo.picture)
                  ? <Avatar shape={"circle"} src={userInfo.avatar || userInfo.picture} />
                  : <Avatar shape={"circle"} icon={<UserOutlined/>} />
                  }
                  <span className="user-name">{
                      (userInfo.name || userInfo.display_name || (userInfo.given_name+" "+userInfo.family_name).trim() || ( userInfo.nickname || userInfo.login || userInfo.username ) )
                  }</span>
                </span>
              )}
  </>);
}
