import "./style.css"

import React, {useEffect, useRef, useState} from "react";
import {highLightMake} from "../../../../core/HighLighter";

import Dialog from "../../../../core/Dialog";
import ReactDOM from "react-dom/client";
import {getOrCreate} from "../../../../core/oth";
import {gFetch} from "../../../../core/token";

export default function Builder({
                                    template={},
                                    onClose = ()=>{},
}){
    // const {config, curUserInfo, path, setPath, app, setApp} = useContext(GlobalContext)

    const uref = useRef()
    const [el, setEl] = useState({innerHTML: "", id: 0})

    useEffect(() => {
        if(template.code  // только если выбран какой-либо шаблон
            && !(el.innerHTML) // и ещё не получен код формы
        ) {
            window["gAlert"] = (text)=>(ReactDOM.createRoot(getOrCreate()).render((
                <Dialog
                    title = "Внимание!"
                    buttonCaption={null}
                    buttonAction={(e)=>{}}
                    closeAction={onClose}
                >
                    <pre>{text}</pre>
                </Dialog>
            )))

            gFetch(
                "/api/form/" + encodeURIComponent(template.code)
                + "/" + encodeURIComponent(template.src || "")
                + "?withDetails=t&target=smart&alert=window['gAlert']"
                + (window.location.search.split("?")[1] ? "&values="+(window.location.search.split("?")[1]) : "")
            )
                .then(it => {
                    return it.text();
                })
                .then(it => {
                    setEl( ((t) => {
                        const bf = document.createElement("div");
                        bf.innerHTML = t;
                        return bf.querySelector(".formContainer");
                    })(it) );
                })
        }
    }, []);

    useEffect(() => {
        if(el.id !== 0) {
            try {
                Array.from(document.querySelectorAll("#" + el.id + " form > .buttons")).forEach(it => {
                    document.querySelector("#" + el.id).appendChild(it)
                    // document.querySelector("#" + el.id + " .buttons [type='submit']").addEventListener("click", onClose)
                    document.querySelector("#" + el.id + " .buttons [type='reset']").addEventListener("click", onClose)
                });

                Array.from(document.querySelectorAll("#" + el.id + " script")).forEach(it => {
                    eval((it.textContent || ""));
                });

                highLightMake(document.querySelector("#" + el.id + " form #idata"), "xml")

                const ua = document.createElement("input");
                ua.type = "hidden"; ua.name = "_ua"; if(template.ua){ ua.value = template.ua }
                document.querySelector("#" + el.id + " form").appendChild(ua)

            } catch (e) {
                console.log(e)
            }
        }
    }, [el]);

    return (!el.innerHTML
           ? <></>
           : <Dialog
                id={el.id}
                ref={uref}
                className={el.className}
                title={el.querySelector("h3").textContent}
                buttonCaption=""
                closeAction={(e)=>{
                    onClose && onClose()
                }}
            >
                <div dangerouslySetInnerHTML={ {__html: el.innerHTML} }/>
             </Dialog>
    )
}
