import {gFetch} from "../../core/token";
let aborterFetchUserInfo // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!

export const fetchUserInfo = (userId, setter, stateSetter)=> {

    if(aborterFetchUserInfo){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        // aborterFetchUserInfo.abort() // отменить выполняющийся запрос
        return; // значит новый запрос не выполнять — хватит и одного
    }
    aborterFetchUserInfo = new AbortController(); // на каждый запрос нужен свой абортарий

    stateSetter({type: "wait", description: "Подготавливается запрос"}) // setter( [] );
    gFetch(
        "/api/users/"+userId
        ,{
            signal: aborterFetchUserInfo.signal // привязка запроса к абортарию
        })
        .then(it => {
            stateSetter({type: "wait", description: "Передаётся информация о пользователе"})
            return it.json()
        })
        .then( usr => {
            if(usr.sub != userId){ // пришло что-то не то
                setter(usr);
                aborterFetchUserInfo = null;
                stateSetter({type: "no"})
            }else {
                stateSetter({type: "wait", description: "Подготавливается информация о пользователе"})
                const pic = usr.avatar || usr.picture;

                if (pic && pic.slice(0, 4).toLowerCase() === "http") { //если url, то получаем по нему картинку
                    stateSetter({type: "pic", description: "Подготавливается картинка пользователя"})
                    const kache = sessionStorage.getItem(pic);
                    if (kache) {
                        usr.avatar = kache;
                        setter(usr);
                        aborterFetchUserInfo = null;
                        stateSetter({type: "ok"})
                    } else {
                        gFetch(pic, {method: "GET", headers: {"Authorization": "Bearer " + window.acc}})
                            .then(it => {
                                return it.blob()
                            })
                            .then((it) => {
                                const fr = new FileReader();
                                fr.readAsDataURL(it);
                                fr.onloadend = () => {
                                    usr.avatar = "";
                                    if (fr.result.indexOf("data:application/json") < 0) { //если не ошибка
                                        sessionStorage.setItem(pic, fr.result); //кешируем
                                        usr.avatar = fr.result; //сохраняем у пользователя вместо url саму картинку
                                        setter(usr); // перерисовываем инфо о пользоваетеле
                                    } else {
                                        setter(usr); // если ошибка, то не кешируем
                                    }
                                    aborterFetchUserInfo = null;
                                    stateSetter({type: "ok"})
                                }
                            })
                    }
                } else {
                    setter(usr);
                    aborterFetchUserInfo = null;
                    stateSetter({type: "ok"})
                }
            }
        })
        .catch( err => {
            aborterFetchUserInfo = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
            if(err.name === "AbortError") return;
            stateSetter({type: "error", description: err.message })
        })
}
