import React from "react";
import {linkTo} from "../oth";

export default function ImageMD({
                                     src="",
                                     alt = "",
}) {
    return(
        <img src={
            linkTo(
                window.location.pathname.split("/").slice(0,-1).join("/")
                +"/"
                +src
            )
                .replace("/mans/", "/man/")
        } alt={alt} />
    )
}
