import React, {useContext} from "react";
import {saveToken} from "../../core/token";
import {linkTo} from "../../core/oth";
import {GlobalContext} from "../GlobalContext";
import {Button} from "antd";
import User from "../User";

export default function LogInOut() {
    const {
        // config,
        curUserInfo,
        // path,
        // setPath,
    } = useContext(GlobalContext)

    return (curUserInfo.sub ? (
                <p className={"userInfo elastic"}>
                    <User sub={curUserInfo.sub} />
                    <Button
                        type="text"
                        onClick={(e) => {
                            saveToken({}) // сбросить-обнулить токены
                            window.location = window.location + "?apus=" // просто, чтоб перезагрузилась страница
                        }}
                    >Выход</Button>
                </p>)
                : (<p className={"login elastic"}>
                    <Button
                        type="text"
                        onClick={(e) => {
                            window.location = linkTo("/login?redirectUrl=" + window.location)
                        }}
                    >Авторизация</Button>
                </p>)
  )
}
