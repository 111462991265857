import "./style.css";

import React, {useContext, useEffect, useState} from "react";

import {Button, Tree} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {fetchCategories, recHier, showFormForAddCategory} from "./funcs";
import {GlobalContext} from "../../GlobalContext";
import {changeItem} from "../../../core/list";

export const allCats = {key: "∞", title: "[всё]"}
export const woCat   = {key: "∅", title: "[без категории]"}

export default function Categories({}) {
    const {
        // config,
        // curUserInfo,
        // path, setPath,
        app, setApp,
    } = useContext(GlobalContext)

    const [selectedKeys, setSelectedKeys] = useState([allCats.key])

    const [categories, setCategories] = useState([])
    const [treeData, setTreeData] = useState([allCats, woCat])

    useEffect(() => {
        if(app && app.storage && app.storage.code // определено хранилище
        ) {
            if(
                (!(categories.length)                          // либо не получены категории
                || (categories[0].src !== app.storage.code) )  // или они из другого хранилища
            ) {
                fetchCategories(app.storage.code, (cats) => {
                    setCategories(cats)
                })
            }
            if( app.category && (app.category.key || app.category.name)) {
                setSelectedKeys([app.category.key || app.category.name])
            }else{
                setSelectedKeys([allCats.key])
            }
        }
    }, [app]);

    useEffect(() => {
        try {
            const cats = recHier(categories, categories.filter((it) => (it.title && !(it.category))), setCategories, setSelectedKeys)
            setTreeData([allCats, woCat, ...cats])
        }catch(_){
            setTreeData([])
        }
    }, [categories]);

    return (<>
        {
            !(app && app.storage && (app.storage.permissions || []).indexOf("catAdd") >= 0) ? <></>
                : <Button className={"add"} size={"small"}
                    onClick={(e)=>showFormForAddCategory(e,{src:app.storage.code, category: null, title: "Новая категория"}, (ne)=>{
                        setCategories( changeItem(categories, ne) )
                        setSelectedKeys( ne )
                    })}
                ><PlusOutlined/></Button>
        }

        <Tree
            showLine
            autoExpandParent={true}
            blockNode={true}
            // switcherIcon={<DownOutlined />}
            // defaultSelectedKeys={ selectedKeys }
            selectedKeys={ selectedKeys }
            expandedKeys={ selectedKeys }
            // defaultExpandedKeys={ selectedKeys }
            onSelect={(sk)=>{
                setSelectedKeys(sk)
                const cat = categories.filter( it => ((it.key || it.name) === sk[0]))[0] || {src: app.storage.code, name: sk[0]}
                setApp(Object.assign({}, app, {category: cat}))
                // setPath(`/src/${cat.src}/cat/${cat.name}`)
            }}
            treeData={treeData}
        />
    </>)

}
