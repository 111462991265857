import "./style.css"

import React, {useRef, useState} from "react";

import Dialog from "../../../../core/Dialog";
import {InboxOutlined, PlusCircleTwoTone} from "@ant-design/icons";
import {Form, Upload} from "antd";
const { Dragger } = Upload;

export default function TemplateAdder({
                                   template = {}, // as TemplateBaseInfo
                                   onSave = (form)=>{},
                               }){

    const [file, setFile] = useState(null)
    const fref = useRef()

    return (<Dialog
        className={"template-adder"}
        title={<>
            <PlusCircleTwoTone/>
            Загрузка шаблона
        </>}
        buttonCaption="Сохранить"
        buttonAction={(e, cb) => {
            const fd = new FormData(fref.current.nativeElement)
            fd.set("tfile", file)
            onSave( fd );
            cb && cb(true);
        }}
    >
        <Form
            ref={fref}
            onSubmit={(e) => {
                e.preventDefault();
                return
            }}
        >
            <input type={"hidden"} name={"src"} value={template.src}/>
            <input type={"hidden"} name={"category"} value={template.category}/>
            <input type={"hidden"} name={"requestType"} value={"templateAdd"}/>

            <Dragger
                name={"tfile"}
                accept={".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                maxCount={1}
                onRemove={(_) => {
                    setFile(null)
                }}
                beforeUpload={(file) => {
                    setFile(file)
                    return false;
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Для загрузки — нажмите здесь, или перетащите сюда файл</p>
                <p className="ant-upload-hint">
                    Поддерживаются форматы xlsx и docx
                </p>
            </Dragger>
        </Form>
    </Dialog>)
}
