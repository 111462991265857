import "./style.css";

import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../GlobalContext";
import {Layout, Menu} from "antd";

import {
    DatabaseOutlined,
    FolderOutlined,
} from '@ant-design/icons';

import {fetchStorages} from "./funcs";
import {allCats} from "../Welcome/Categories";

function getKeysByPath(path = "", arr = []){
    let finded = []
    arr.forEach(it => {
        if( path.indexOf(it.key)===0){
            finded.push(it.key)
        }
        if(it.children){
            finded = [...finded, ...getKeysByPath(path,it.children)];
        }
    })
    return finded;
}

export default function Left() {

    const {
        config, // curUserInfo,
        // path, setPath,
        app, setApp,
    } = useContext(GlobalContext)

    const [collapsed, setCollapsed] = useState(false)

    const [storages, setStorages] = useState([])
    const [state, setState] = useState({})

    useEffect(() => {
        fetchStorages(setStorages, setState)
    }, []);

    useEffect(() => {
        if(
        (!(app.storage) && storages && config["STORAGE_DEFAULT"])
            || ( app.storage && !(app.storage.type) ) // если это информация из URL — то там не будет type
        )
        {
            if(app.storage && app.storage.type) app.category = allCats // если это информация из URL — то там не будет type
            setApp(Object.assign({}, app, {storage: storages.filter(s=>(s.code === config["STORAGE_DEFAULT"]))[0], category: app.category}))
            // setPath("/src/"+config["STORAGE_DEFAULT"]+"/cat/"+(allCats.key))
        }
    }, [storages, app]);

    return (<Layout.Sider
        id={"leftaside"}
        theme={"light"}
        collapsible collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
    >
        <span className="logo">
            <img src="/favicon.ico" alt="iy" onClick={() => {setApp({})}} />
        </span>

        <Menu
            mode="inline"
            selectedKeys={ [(app.storage||{}).code] }
            onClick={(e) => {
                setApp( Object.assign({}, app, {storage: storages.filter( s => s.code===e.key )[0], category: allCats }) )
                // setPath("/src/"+e.key+"/cat/"+(allCats.key));
            }}
            items={
                storages.map(it=>({
                    key:   it.code,
                    label: it.caption,
                    icon: (it.type==="sql" ? <DatabaseOutlined /> : <FolderOutlined /> ),
                }))
            }
        />
    </Layout.Sider>)
}
