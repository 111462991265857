import "./style.css";

import React, {useEffect, useState, useId, useRef} from "react";
import {Button} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
} from "@ant-design/icons";

const diaClose = (dia)=>{
    if(!dia) return false;
    if( !(dia.classList.contains("gnzdia-fade") || dia.classList.contains("gnzdia"))) return false;

    if( !(dia.classList.contains("gnzdia") )) dia = dia.querySelector(".gnzdia") || dia;

    Array.from( dia.querySelectorAll("form") ).forEach((f)=>{f.reset()});
    dia.parentNode.removeEventListener("keydown", diaCloseByEsc);
    if("close"  in dia) dia.close();
    if("remove" in dia) dia.parentNode.remove();
    return true;
}

const diaCloseByEsc = (e, act)=>{
    if(e.key === "Escape"){
        let o = e.target
        while (o && !(o.classList && o.classList.contains("gnzdia-fade"))) o = o.parentNode;
        const dia = (o || document).querySelector(".gnzdia")
        if (dia) {
            e.preventDefault()
            e.stopPropagation()
            act && act(e)
            return diaClose(dia)
        }
    }
    return false;
}

export const diaCloser = (e) =>{
    const dia = e.target.querySelector(".gnzdia")
    if(!dia) return false;

    if(e.type==="mousedown"){
        dia.setAttribute("data-mdd", Date.now());
        return false;
    }
    if(e.type==="mouseup"){
        const md = dia.getAttribute("data-mdd");
        if((md||0) === 0) return;
        const df = Date.now() - md;
        if(df > 300) return false;
    }

    const rect = dia.getBoundingClientRect();
    if( !(
        rect.top  <= e.clientY && e.clientY <= rect.top  + rect.height &&
        rect.left <= e.clientX && e.clientX <= rect.left + rect.width
    ) ) {
        return diaClose(dia);
    }
    return false;

}

const makeDialogDraggable = () =>{
    function getCoordsByTransform3D(o) {
        if (o === "")
            return {x: 0, y: 0, z: 0};
        const t = o.match(
            /translate3d\((?<x>[-.\d]+)(?:px)?,\s*(?<y>[-.\d]+)(?:px)?,\s*(?<z>[-.\d]+)(?:px)?\)/
        );
        return !t || !t.groups ? {x: 0, y: 0, z: 0} : {
            x: Number(t.groups.x),
            y: Number(t.groups.y),
            z: Number(t.groups.z)
        };
    }

    function getDia(o) {
        let t = !1;
        for (let e = 0; e < o.length; e++) {
            const s = o[e];
            if (s.tagName === "BUTTON" || s.tagName === "A") return null;
            if (s && s.classList && s.classList.contains("gnzdia") && t) return s;
            s.hasAttribute && s.hasAttribute("data-dialog-dragger") && (t = !0);
        }
        return null;
    }

    const globalPointerEvent = (o) => {
        const t = o.target;
        if (!t) return;
        let dia;
        if (t.classList.contains("gnzdia")) dia = t;
        else {
            const r = getDia( o.composedPath() );
            if (!r) return;
            dia = r;
        }
        const {x: s, y: g} = getCoordsByTransform3D(dia.style.transform);
        const a = o.clientX - dia.offsetLeft - s
        const i = o.clientY - dia.offsetTop  - g;
        const m = -dia.offsetLeft;
        const h = window.innerWidth  - dia.offsetLeft - dia.offsetWidth;
        const p = -dia.offsetTop;
        const x = window.innerHeight - dia.offsetTop  - dia.offsetHeight;
        if (a < 0 || a > dia.offsetWidth || i < 0 || i > dia.offsetHeight) return;

        function transformTo(n, r) {
            const f = n - dia.offsetLeft - a;
            const y = r - dia.offsetTop - i;
            const L = Math.min(Math.max(m, f), h);
            const v = Math.min(Math.max(p, y), x);

            dia.style.transform = `translate3d(${L}px, ${v}px, 0)`;
        }

        dia.setPointerCapture(o.pointerId);

        const onPointerMove = (n) => transformTo(n.clientX, n.clientY);

        const onTouchMove = (n) => {
            n.preventDefault();
            transformTo(n.touches[0].clientX, n.touches[0].clientY);
        };

        dia.addEventListener("pointermove", onPointerMove);
        dia.addEventListener("touchmove",   onTouchMove);
        dia.addEventListener("pointerup", (n) => {
            n.preventDefault();
            dia.releasePointerCapture(n.pointerId);
            dia.removeEventListener("pointermove", onPointerMove);
            dia.removeEventListener("touchmove",   onTouchMove);
        }, {once: !0} );
    };

    (function() {
        window.removeEventListener("pointerdown", globalPointerEvent);
        window.addEventListener("pointerdown",    globalPointerEvent);
    })();
}
makeDialogDraggable();

export default function Dialog({
                       title = (<></>),
                       id = "",
                       className = "",
                       children = (<></>),
                       buttonAction = (_/*e*/)=>{},
                       buttonCaption = "Ок",
                       closeAction = (_/*e*/)=>{},
                   }) {
    const tId = useId();
    const dref = useRef();
    const [open, setOpen] = useState(true);

    useEffect(()=>{
        const dia = dref.current
         if(open){
             if("showModal" in dia) dia.showModal();
         }else{
             if("close"  in dia) dia.close();
             if("remove" in dia) dia.remove();
             closeAction && closeAction();
         }
    }, [open, closeAction]);

    (function() {
        const diaCloseByEscGlb = (e) => {
            diaCloseByEsc(e, closeAction)
        }
        window.removeEventListener("keyup", diaCloseByEscGlb);
        window.addEventListener("keyup",    diaCloseByEscGlb);
    })();

    return (<div
        ref={dref}
        className={"gnzdia-fade"}
        onMouseDown={(e) => {
            if (diaCloser(e)) {
                closeAction && closeAction(e)
            }
        }}
        onMouseUp={(e) => {
            if (diaCloser(e)) {
                closeAction && closeAction(e)
            }
        }}
        onKeyDown={(e) => {
            if (diaCloseByEsc(e)) {
                closeAction && closeAction(e)
            }
        }}
    >
        <div
             id={id || tId}
             aria-labelledby={(id || tId) + "-title"}
             className={`gnzdia ${className}`}
             onCancel={(e) => {
                 if (diaCloser(e)) {
                     closeAction && closeAction(e)
                 }
             }}
        >
            <h3 id={(id || tId) + "-title"} data-dialog-dragger>{title}</h3>
            <CloseOutlined
                className={"close"}
                onClick={(_/*e*/) => {
                setOpen(false)
            }} />
            {children}
            {buttonCaption
                ? <fieldset className="buttons">
                    <Button
                        className="submit"
                        icon={<CheckOutlined/>}
                        onClick={(e) => {
                            buttonAction(e, (r) => {
                                r && setOpen(false)
                            })
                        }}
                    >{buttonCaption}</Button>
                    <Button
                        className="close reset"
                        icon={<CloseOutlined/>}
                        onClick={(_/*e*/) => {setOpen(false)
                    }}>Отмена
                    </Button>
                </fieldset>
                : <></>
            }
        </div>
    </div>)
}
