export const entries = (o)=> {
    if("target" in o && !("nodeName" in o)){
        o = o.target
    }
    if(o.nodeName === "FORM") o = new FormData(o)
    try {
        return Object.fromEntries(o)
    }catch (_) {
        return o
    }
}

export const asFormData = (o)=> {
    if(o.constructor.name==="FormData") return o;
    if("target" in o && !("nodeName" in o)){o = o.target}
    if(o.nodeName === "FORM") return (new FormData(o))
    const f = new FormData()
    const d = entries(o)
    for(const k in d) f.set(k, d[k])
    return f
}
