import React from 'react';
import ReactDOM from 'react-dom/client';

import "./normalize.css";
import './style.css';

import { ConfigProvider, App } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import Application from "./Application";

ReactDOM.createRoot(document.getElementById("app")).render(
  <React.StrictMode>
      <ConfigProvider locale={ruRU}>
          <App>
              <Application/>
          </App>
      </ConfigProvider>
  </React.StrictMode>
);
