const clientPort = "3000";
const serverPort = "3080";

export const getStateObj = ()=>( (window.history.state || {}).obj || {} )

export const linkTo = (str)=>(
        (window.location.port === clientPort && str.indexOf("/")===0 )
            ? window.location.origin.replace(":"+clientPort, ":"+serverPort)
            : ""
    )
    + ((str.indexOf("/")!==0 && str.indexOf("http")!==0) ? window.location.pathname.replace(/[^/]+$/, "") : "")
    + (str||"")

export const getOrCreate = (elId="defArea", tag = "div", parent = document.body) =>{
    const el = document.getElementById(elId) || document.createElement(tag);
    el.id = el.id || elId;
    if(!el.parentNode) parent.appendChild(el);
    return el;
}
