import './style.css';

import React, {useContext, useEffect, useState} from "react";

import {gFetch} from "../../core/token";
import {GlobalContext} from "../GlobalContext";
import MD from "../../core/MD";


export default function Man() {
    const {
        // config,
        // curUserInfo,
        path,
        setPath,
    } = useContext(GlobalContext)

    const [content, setContent] = useState("")
    const [toc, setToc] = useState("")

    useEffect(() => {
        gFetch( ("/mans/" === path ? path+"index.md" : path).replace("/mans/", "/man/"))
          .then( it=> it.text() )
          .then( it => setContent(it) )
    }, [path]);

    useEffect(() => {
        gFetch("/man/toc.md")
            .then( it => it.text())
            .then( it => setToc(it))
    }, []);

    return (<div className={"man"}>
        <div className={"toc"}>
            <MD
                setPath={setPath}
            >{toc}</MD>
        </div>
        <MD
            setPath={setPath}
        >{content}</MD>
    </div>);
}
