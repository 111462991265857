import "./style.css";

import React from "react";
import {Layout, theme} from "antd";
import Menu from "./Menu";
import GitLink from "./GitLink";
import LogInOut from "./LogInOut";

export default function Header() {
    const {
        token: { colorBgContainer },
    } = theme.useToken()

    return (<Layout.Header
            id={"header"}
            style={{
                background: colorBgContainer
            }}
        >
            <LogInOut/>
            <Menu/>
            <GitLink/>
      </Layout.Header>
  )
}
