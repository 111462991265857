import ReactDOM from "react-dom/client";

import {gFetch} from "../../../core/token";
import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
} from "@ant-design/icons";
import {changeItem} from "../../../core/list";
import CategoryEditor from "./CategoryEditor";
import {getOrCreate} from "../../../core/oth";
import {Button, Space} from "antd";

export const recHier = (arr, start, setter, selector)=> start.map( it => {
    it.children = recHier(arr, arr.filter((c) => ((c.name || c.title) && it.id === c.category)), setter, selector)
    it.name = it.name || `${it.title}`
    it.key = it.key || it.name;
    if(setter) {
        it.title = <>{[
            it.name,
            (!setter ? <></>
                    :
                    <Space>
                        <Space.Compact>
                            {[
                                ((it.permissions.indexOf("upd") < 0) ? <></>
                                        : <Button className={"upd"} size={"small"}
                                                  onClick={(e) => showFormForUpdCategory(e, it, (ne) => {
                                                      setter(changeItem(arr, ne))
                                                      // selector(ne)
                                                  })}
                                        ><EditOutlined/></Button>
                                ),
                                ((it.permissions.indexOf("del") < 0) ? <></>
                                        : <Button className={"del"} size={"small"}
                                                  onClick={(e) => showFormForDelCategory(e, it, (ne) => {
                                                      setter(
                                                          Object.assign([], arr)
                                                              .filter((l) => (`${l.id}` !== `${it.id}`))
                                                      )
                                                      // selector( arr.find((c)=>(`${c.id}` === `${it.category}`)) )
                                                  })}
                                        ><DeleteOutlined/></Button>
                                ),
                                ((it.permissions.indexOf("upd") < 0) ? <></>
                                        : <Button className={"add"} size={"small"}
                                                  onClick={(e) => showFormForAddCategory(e, {
                                                      src: it.src,
                                                      category: it.id,
                                                      title: "Новая категория"
                                                  }, (ne) => {
                                                      setter(changeItem(arr, ne))
                                                      // selector(ne)
                                                  })}
                                        ><PlusCircleOutlined/></Button>
                                ),
                            ]}
                        </Space.Compact>
                    </Space>
            )
        ]}</>
    }else{
        it.value = it.key
    }
    return it
})

export const fetchCategories = (src, setter) => {
    gFetch("/api/categories?src="+(src==="/" ? "" : src ), {method: "GET"})
        .then( it => { return it.json() })
        .then( it => {
            if(it) {
                if (it.type === "err") {
                    alert(it.id + ": " + it.title + "\r\n\r\n" + it.category)
                } else {
                    setter( it )
                }
            }
        })
}

export const saveCategory = function (o, afterSave) {
    const formData = ( (o && o.nodeName && o.nodeName === "FORM") ? (new FormData(o)) : (new FormData()))
    if(!(o && o.nodeName && o.nodeName === "FORM")) for(const k in o) formData.set(k, o[k]);

    gFetch("/api/categories", {method: "POST", body: formData})
        .then( it => { return it.json() })
        .then( it => {
            if( it.type === "err" ){
                alert( it.id +": "+ it.title + "\r\n\r\n" + it.category)
            }else {
                it.name = it.title
                afterSave && afterSave(it)
            }
        });
}

export const showFormForAddCategory = (event, cat, setter)=>{
    event.preventDefault()
    event.stopPropagation()
    ReactDOM.createRoot(getOrCreate()).render(
        <CategoryEditor cat={cat} onSave={(c)=>{saveCategory(c, setter)}} />
    )
}
export const showFormForUpdCategory = (event, cat, setter)=>{
    event.preventDefault()
    event.stopPropagation()
    ReactDOM.createRoot(getOrCreate()).render(
        <CategoryEditor cat={cat} onSave={(c)=>{saveCategory(c, setter)}} />
    )
}
export const showFormForDelCategory = (event, cat, setter)=>{
    event.preventDefault()
    event.stopPropagation()
    if(window.confirm("Точно удалить?")){
        saveCategory(Object.assign(cat, {requestType: "categoryDelete"}), setter)
    }
}
