import "./style.css"

import React, {useEffect, useId, useRef, useState} from "react";

import Dialog from "../../../../core/Dialog";
import HighLighter from "../../../../core/HighLighter";
import {DownloadOutlined, EditTwoTone, InboxOutlined} from "@ant-design/icons";
import {Checkbox, Form, Input, TreeSelect} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {linkTo} from "../../../../core/oth";
import TextArea from "antd/es/input/TextArea";
import {fetchCategories, recHier} from "../../Categories/funcs";

export default function TemplateEditor({
                                   template = {}, // as TemplateBaseInfo
                                   onSave = (form)=>{},
                               }){

    const [activeTab, setActiveTab] = useState("main")
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState(template.category)
    const [cache, setCache] = useState(template.cacheMaxAge||0)
    const [file, setFile] = useState(null)

    const id = useId()

    const teref = useRef()
    const fref = useRef()

    useEffect(() => {
        template.category = category
    }, [category]);

    useEffect(()=>{
        fetchCategories(
            template.src,
            (cats)=>{ setCategories( recHier(cats, cats.filter((it) => !(it.category))) ) }
        )
    }, [])

    // useEffect(() => {console.log(categories)}, [categories]);

    return (<Dialog
        ref={teref}
        className={"template-editor type-" + template.type + " baseFormat-" + template.type.slice(0, 3)}
        title={<>
            <EditTwoTone/>
            Редактирование шаблона {template.code}
        </>}
        buttonCaption="Сохранить"
        buttonAction={(e, cb) => {
            const fd = new FormData(fref.current.nativeElement)
            fd.set("tfile", file)
            fd.set("category", category)
            onSave( fd );
            cb && cb(true);
        }}
    >
        <Form className={"tabs"}
              ref={fref}
              onSubmit={(e) => { e.preventDefault(); return }}
        >

            <div className="tablist" role="tablist" aria-labelledby="label">
                <button className="tab" role="tab" type="button"
                        id={"EditorForm-tab" + id + "-main"}
                        aria-controls={"EditorForm-tabpanel" + id + "-main"}
                        onClick={()=>{setActiveTab("main")}} aria-selected={(activeTab==="main")} tabIndex={(activeTab==="main" ? "0" : "-1")}
                >Основное</button>
                <button className="tab" role="tab" type="button"
                        id={"EditorForm-tab" + id + "-form"}
                        aria-controls={"EditorForm-tabpanel" + id + "-form"}
                        onClick={() => {
                            setActiveTab("form")
                        }} aria-selected={(activeTab === "form")} tabIndex={(activeTab === "form" ? "0" : "-1")}
                >Форма
                </button>
                <button className="tab" role="tab" type="button"
                        id={"EditorForm-tab" + id + "-example"}
                        aria-controls={"EditorForm-tabpanel" + id + "-example"}
                        onClick={()=>{setActiveTab("example")}} aria-selected={(activeTab==="example")} tabIndex={(activeTab==="example" ? "0" : "-1")}
                >Пример</button>
                {0 > template.permissions.indexOf("shw_generator")
                    ? <></>
                    : <button className="tab" role="tab" type="button"
                              id={"EditorForm-tab" + id + "-generator"}
                              aria-controls={"EditorForm-tabpanel" + id + "-generator"}
                              onClick={() => {
                                  setActiveTab("generator")
                              }} aria-selected={(activeTab === "generator")}
                              tabIndex={(activeTab === "generator" ? "0" : "-1")}
                    >Генератор</button>
                }
                <p data-dialog-dragger style={{flexGrow: 1}}></p>
            </div>

            <div className={"tabpanel EditorForm-tabpanel-main "+((activeTab==="main") ? "" : "tabpanel--hidden")} role="tabpanel" id={"EditorForm-tabpanel" + id + "-main"} tabIndex="0"
                 aria-labelledby={"EditorForm-tab" + id + "-main"}>

                <fieldset className="template-code">
                    <legend>Код</legend>
                    <Input type="text" name="code" value={template.code || ""} readOnly required/>
                </fieldset>
                <fieldset className="template-title">
                    <legend>Наименование</legend>
                    <Input type="text" name="title" defaultValue={template.title || ""} required readOnly={0>template.permissions.indexOf("upd_title")} />
                </fieldset>
                <fieldset className="template-category">
                    <legend>Категория</legend>
                    {
                        (0>template.permissions.indexOf("upd_category"))
                            ? <Input type="text" name="category" value={category}
                                     required={0<=template.permissions.indexOf("upd_category")}
                                     readOnly={0>template.permissions.indexOf("upd_category")}
                            />
                            :
                            <TreeSelect
                                treeData={categories}
                                value={category}
                                onChange={setCategory}
                                placeholder="Необходимо выбрать категорию"
                                treeDefaultExpandAll
                                style={{width: "100%"}}
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: "auto",
                                }}
                                treeLine={true}
                                // allowClear
                                // onPopupScroll={}
                                // showSearch
                            />
                    }
                </fieldset>
                <fieldset className="template-keywords">
                    <legend>Ключевые слова</legend>
                    <Input type="text" name="keywords" defaultValue={template.keywords || ""} readOnly={0>template.permissions.indexOf("upd_keywords")} />
                </fieldset>
                <fieldset className="template-description">
                    <legend>Описание</legend>
                    <TextArea name="description" defaultValue={template.description || ""} readOnly={0>template.permissions.indexOf("upd_description")} />
                </fieldset>
                <fieldset className="template-cacheMaxAge">
                    <legend>Актуальность кеша</legend>
                    <p>
                        <label>
                        <Input type={"radio"} checked={cache < 0}  onClick={(e)=>{ setCache(-3600000) }}  readOnly={0>template.permissions.indexOf("upd_cache")} /> Не кэшировать</label>
                        <label><Input type={"number"} value={ (cache <= 0) ? "" : (cache||0)/3600000} onInput={(e)=>{ setCache(e.target.value * 3600000) }}  readOnly={0>template.permissions.indexOf("upd_cache")} /> часов</label>
                        <label><Input type={"radio"} checked={cache === 0} onClick={(e)=>{ setCache(0) }}  readOnly={0>template.permissions.indexOf("upd_cache")} /> Вечно</label>
                    </p>
                    <input type="hidden" name="cacheMaxAge" value={cache}/>
                </fieldset>
                <fieldset className="template-tfile">
                    <legend>Файл шаблона</legend>
                    <Dragger
                        name={"tfile"}
                        accept={template.type.slice(0, 3) === "doc" ? ".docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" : ".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"}
                        maxCount={1}
                        readOnly={0>template.permissions.indexOf("upd_file")}
                        onRemove={(_) => {
                            setFile(null)
                        }}
                        beforeUpload={(file) => {
                            setFile(file)
                            return false;
                        }}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Чтобы заменить шаблон — нажмите здесь, или перетащите сюда файл</p>
                        <p className="ant-upload-hint">
                            Файл должен быть фомата {template.type.slice(0, 3) === "doc" ? "docx" : "xlsx"}
                        </p>
                    </Dragger>
                    <a id={"dwnld-" + id} className="button small download"
                       href={linkTo( "/api/render")+"?tcode=" + template.code + "&src=" + template.src}>
                        <DownloadOutlined/>
                        Скачать текущий шаблон
                    </a>
                </fieldset>

                {(template.type.slice(0, 3) === "doc")
                    ? <fieldset className="template-mixaly4">
                        <legend>Mixaly4-Style</legend>
                        <Checkbox
                            name="mixaly4"
                            value="true"
                            defaultChecked={template.mixaly4 || false}
                            readOnly={0>template.permissions.indexOf("upd_docstyle")}
                        />
                    </fieldset>
                    : <></>
                }
            </div>
            <div className={"tabpanel EditorForm-tabpanel-form "+((activeTab==="form") ? "" : "tabpanel--hidden" )} role="tabpanel" id={"EditorForm-tabpanel" + id + "-form"} tabIndex="0"
                 aria-labelledby={"EditorForm-tab" + id + "-form"}>
                <fieldset className="template-form">
                    <legend>Форма запроса данных</legend>
                    <HighLighter
                        name="form"
                        content={template.form || ""}
                        language="xml"
                        readOnly={0>template.permissions.indexOf("upd_form")}
                    />
                </fieldset>
            </div>
            <div className={"tabpanel EditorForm-tabpanel-example "+((activeTab==="example") ? "" : "tabpanel--hidden" )} role="tabpanel" id={"EditorForm-tabpanel" + id + "-example"} tabIndex="0"
                 aria-labelledby={"EditorForm-tab" + id + "-example"}>
                <fieldset className="template-example">
                    <legend>Пример данных</legend>
                    <HighLighter
                        name="example"
                        content={template.example || ""}
                        language="xml"
                        readOnly={0>template.permissions.indexOf("upd_example")}
                    />
                </fieldset>
            </div>
            {0 > template.permissions.indexOf("shw_generator")
                ? <></>
                : <div className={"tabpanel EditorForm-tabpanel-generator "+((activeTab==="generator") ? "" : "tabpanel--hidden")} role="tabpanel" id={"EditorForm-tabpanel" + id + "-generator"} tabIndex="0"
                       aria-labelledby={"EditorForm-tab" + id + "-generator"}>
                    <fieldset className="template-generator">
                        <legend>Код генерирования данных</legend>
                        <HighLighter
                            name="generator"
                            content={template.generator || ""}
                            language="sql"
                            readOnly={0>template.permissions.indexOf("upd_generator")}
                        />
                    </fieldset>
                </div>
            }

            {/*<!--
            <fieldset class="template-type">
                <legend>Тип</legend>
                <input type="text" name="type" defaultValue="${template.type||""}"  required readonly />
            </fieldset>
            <fieldset class="template-author">
                <legend>Автор(id)</legend>
                <input type="text" name="author" defaultValue="${(template.author && template.author.sub)||""}"  required readonly />
            </fieldset>
            <fieldset class="template-img">
                <legend>Картинка</legend>
                <input type="text" name="img" defaultValue="${template.img||""}" />
            </fieldset>
            -->*/}
        </Form>
    </Dialog>)
}
