/**
 * @import {Root} from 'mdast'
 */

import {findAndReplace} from 'mdast-util-find-and-replace'


export default function remarkIssPlugin() {
    return function (tree) {

            findAndReplace(tree, [
                  /#(\w+)/g,
                      function (_, $1) {
                          return {
                              type: 'link',
                              url: `${window.location.origin}/${window.location.pathname.split("/")[1]}/iss/${$1}`,
                              title: `link 2 issue «${$1}»`,
                              children: [{type: 'text', value: `#${$1}`}],
                          }
                      }
                ])

        findAndReplace(tree, [
            /@(\w+)/g,
            function (_, $1) {
                return {
                    type: 'link',
                    url: `${window.location.origin}/users/${$1}`,
                    title: `link 2 user «${$1}»`,
                    children: [{type: 'text', value: `@${$1}`}],
                }
            }
        ])

        findAndReplace(tree, [
            /~(\w+)/g,
            function (_, $1) {
                return {
                    type: 'link',
                    url: `${window.location.origin}/${window.location.pathname.split("/")[1]}/branches/${$1}`,
                    title: `link 2 branch «${$1}»`,
                    children: [{type: 'text', value: `~${$1}`}],
                }
            }
        ])

    }
}
