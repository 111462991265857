import React, {useContext} from "react";
import {GlobalContext} from "../GlobalContext";

export default function GitLink() {
    const {
        config,
        // curUserInfo,
        // path,
        // setPath,
    } = useContext(GlobalContext)

    return ((config["GIT_LINK_CONTENT"] || "").trim().indexOf("<") === 0
                ? <a
                    target={"_blank"} rel="noreferrer"
                    href={config["GIT_LINK_URL"]}
                    dangerouslySetInnerHTML={{__html: config["GIT_LINK_CONTENT"]}}
                  />
                : <a
                    target={"_blank"} rel="noreferrer"
                    href={config["GIT_LINK_URL"]}
                  >
                    <img
                        src={config["GIT_LINK_CONTENT"]}
                        alt={".git"}
                    />
                  </a>
            )
}
