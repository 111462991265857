import {createContext} from "react";
import {gFetch} from "../core/token";

let fetchConfigInProcess = false
export const fillConfig = function ( setter ) {
    if(fetchConfigInProcess) return fetchConfigInProcess;
    const rez = gFetch("/config")
        .then(it => {
            return it && it.json()
        })
        .then(it => {
            setter(it || {})
            fetchConfigInProcess = false;
        })
    fetchConfigInProcess = rez;
    return rez;
}

export const GlobalContext = createContext({
    config: {},                       // конфиг полученный от сервера
    curUserInfo: {},                  // информация о текущем пользователе
    path: "",                         // текущий путь истории
    setPath: (newPath,                // путь для истории
              stateObj={},        // объект будет доступен если вызвать `getStateObj()`
              replace=false, // true — заменить текущее состояние истории, false — добавить путь в историю
              )=>{},
    app: {},
    setApp: (newApp)=>{},
})
