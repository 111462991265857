import {gFetch} from "../../core/token";

let aborterFetchStorages // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!
export const fetchStorages = (setter, stateSetter)=> {

    stateSetter({type: "wait", description: "Подготавливается запрос"}) // setter( [] );

    if(aborterFetchStorages){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        // aborterFetchStorages.abort() // отменить выполняющийся запрос
        return; // значит новый запрос не выполнять — хватит и одного
    }
    aborterFetchStorages = new AbortController(); // на каждый запрос нужен свой абортарий
    gFetch(
        "/api/storages"
        ,{
            signal: aborterFetchStorages.signal // привязка запроса к абортарию
        })
        .then(it => {
            stateSetter({type: "wait", description: "Передаётся перечень хранилищ"})
            return it.json()
        })
        .then( it => {
            stateSetter({type: "wait", description: "Подготавливается перечень хранилищ"})
            setter(it);
            aborterFetchStorages = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
            stateSetter({type: "ok"})
        })
        .catch( err => {
            aborterFetchStorages = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
            if(err.name === "AbortError") return;
            stateSetter({type: "error", description: err.message })
        })
}
